<!--
    Created by 王丽莎 on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：系统管理-员工管理列表
-->
<style lang="less">
#employee-list-content {
  .col-text-strong {
    color: @adornColor;
    font-weight: 600;
  }
  .role-col-value {
    .display-flex();
    justify-content: center;
    align-items: flex-start;
    cursor: help;
    &::after {
      content: '';
      margin-top: 3px;
      background: transparent url("/icon/help.png") no-repeat;
      background-size: 100% auto;
      width: 12px;
      height: 12px;
      filter: grayscale(80%) opacity(60%);
    }
  }
}
</style>

<template>
  <div id="employee-list-content">
    <!-- 查询条件 -->
    <div v-permission="'system:user:query'">
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:user:query'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
      />
    </div>
    <!--弹窗：提交(新增/编辑)-->
    <v-submit
      v-if="!!dialog.submitModel"
      :model="dialog.submitModel"
      @on-close="dialog.submitModel = null"
      @on-sure="onSureSubmit"
    />
    <!--弹窗：修改密码-->
    <v-password
      v-if="dialog.passwordModel"
      :model="dialog.passwordModel"
      @on-close="dialog.passwordModel = null"
      @on-sure="onSurePassword"
    />
    <!--弹窗：小区详情-->
    <yg-community-detail
      v-if="!!dialog.communityDetailModel"
      :data="dialog.communityDetailModel.communities"
      @on-close="dialog.communityDetailModel = null;"
    />
  </div>
</template>

<script>
import { EmployeeDataModel, EmployeeSubmitDataModel } from './model/EmployeeDataModel';
import ColumnMixin from '@/pages/100__systemManager/employeeList/mixin/tableColumn';
import vSubmit from './view/submit.vue';
import vPassword from './view/password.vue';
import SystemApiSet from '@/request/api/SystemApiSet';
import CommonApiSet from '@/request/api/CommonApiSet';
export default {
  components: { vSubmit, vPassword },
  mixins: [ColumnMixin],
  data () {
    return {
      searchOption: {
        mobile: BaseSearchModel.initData('用户手机号', 'mobile', 'Input'),
        username: BaseSearchModel.initData('登录账号', 'username', 'Input')
      },
      settingItems: [
        BaseSettingModel.initData('新增', 'add', 'md-add-circle').setPermission('system:user:add')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        communityDetailModel: null,
        submitModel: null,
        passwordModel: null
      }
    };
  },
  created () {},
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onSureSubmit () {
      const res = this.dialog.submitModel.checkSubmitValid();
      if (res.success) {
        console.log(res);
        this.requestSubmit(res.params);
      } else {
        this.$Message.warning(res.msg);
      }
    },
    onSurePassword () {
      console.log(this.dialog.passwordModel);
      const resData = this.dialog.passwordModel.checkPasswordValid();
      if (resData.success) {
        this.requestPassword(resData.params);
      } else {
        this.$Message.warning(resData.msg);
      }
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'add':
        this.dialog.submitModel = EmployeeSubmitDataModel.createAdditionModel();
        break;
      case 'refresh':
        this.page.current = 1;
        this.requestData();
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      // const resList = EmployeeDataModel.createFakeDataList();
      // this.page.total = resList.length;
      // this.tableData = EmployeeDataModel.initListWithDataList(resList);

      this.tableData = [];
      this.page.total = 0;
      const api = SystemApiSet.getUserListApi;
      const s = this.searchOption;
      api.params = {
        username: s.username.value,
        mobile: s.mobile.value,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = EmployeeDataModel.initListWithDataList(resData.rows);
      });
    },
    // 提交数据
    requestSubmit (params) {
      const api = params.userId ? SystemApiSet.editUserApi : SystemApiSet.addUserApi;
      api.params = params;
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.dialog.submitModel = null;
        this.requestData();
      });
    },
    // 修改密码
    requestPassword (params) {
      const api = CommonApiSet.otherPassword;
      api.params = params;
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.dialog.passwordModel = null;
        this.requestData();
      });
    },
    // 切换状态
    requestChangeStatus (row, status) {
      // const api = {};
      // api.params = {
      //   id: row.id,
      //   status: status ? 1 : 0
      // };
      // setTimeout(() => {
      //   console.log(api);
      //   row.status = status ? 0 : 1;
      //   row.h5StatusSwitch = !status;
      //   this.$Message.warning('操作失败');
      // }, 500);
      const api = SystemApiSet.userStatus;
      api.params = {
        id: row.id,
        status: status ? 1 : 0
      };
      this.$http(api)
        .then(res => {
          console.log(res);
          this.$Message.success('操作成功');
          this.requestData();
        })
        .catch(e => {
          row.status = status ? 0 : 1;
          row.h5StatusSwitch = !status;
        });
    },
    requestDetail (id, isDetail = true) {
      const api = SystemApiSet.getUserInfoApi;
      api.params = { id };
      this.$http(api).then(res => {
        if (isDetail) {
          this.dialog.submitModel = EmployeeSubmitDataModel.initModelWithData(res.data);
        } else {
          this.dialog.communityDetailModel = EmployeeSubmitDataModel.initModelWithData(res.data);
        }
      });
      // this.dialog.settingModel = row;
      // const resData = DemoDetailModel.createFakeData();
      // this.dialog.detailModel = DemoDetailModel.initModelWithData(resData);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
