/**
 * @date:2024/02/02
 * @author 王丽莎
 * @copyright 南京云柜<yun-gui.com>
 * 系统管理相关
 */

import BaseApi from '@/request/base/BaseApi';

class SystemApiSet {
  constructor () {
    // 用户信息相关=员工信息 员工管理相关
    this.getUserInfoApi = BaseApi.createModel('获取用户信息', '/opmp/user/info', true).setMethod(0, false);
    this.getUserListApi = BaseApi.createModel('获取用户信息列表', '/opmp/user/list', true).setMethod(0);
    this.getMenuRoutersApi = BaseApi.createModel('获取用户菜单路由信息', '/opmp/menu/routers', true).setMethod(0);
    this.addUserApi = BaseApi.createModel('新建用户', '/opmp/user/add', true);
    this.editUserApi = BaseApi.createModel('编辑用户', '/opmp/user/edit', true);
    this.userStatus = BaseApi.createModel('user状态', '/opmp/user/changeStatus').setMethod(2);
    this.updatePasswordApi = BaseApi.createModel('修改用户本人密码', '/opmp/user/password/update', true);
  }
}
export default new SystemApiSet();
