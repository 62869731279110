<!--
    Created by 程雨喵'mac on 2024/2/6.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
-->
<style lang="less">
.emp-choose-role-view {
  padding: @containerGap;
  background-color: @backColorStrong;
  border-radius: @borderRadiusMid;
  .emp-choose-role-li {
    margin-bottom: @containerGap;
    display: flex;
    align-items: flex-start;
    .check-box {
      .flex-grow(35px);
      .number-circle {
        .display-flex();
        width: 20px;
        height: 20px;
        font-size: 12px;
        font-weight: 600;
        background-color: @themeColor;
        color: @textColorFlip;
        border-radius: 50%;
      }
    }
    .role-info {
      flex: 1;
      div {
        font-weight: 500;
        font-size: 15px;
        color: @textColorStrong;
      }
      p {
        margin-top: 10px;
        color: @textColorLight;
        font-size: 12px;
      }
    }
  }
}
</style>

<template>
  <yg-drawer
    :title="chooseType === 1 ? '已选角色-查看详情' : '选择角色'"
    :back-type="2"
    width="500"
    :show-bottom="chooseType === 2"
    :sure-button-disabled="sureButtonDisabled"
    sure-button-disabled-text="请至少选择一个角色"
    @on-close="onClose"
    @on-sure="onSure"
  >
    <!--内容详情-->
    <div
      slot="content"
      class="emp-choose-role-view"
    >
      <div
        v-for="(item, i) in roleDataList"
        :key="i"
        class="emp-choose-role-li"
      >
        <div
          class="check-box"
        >
          <Checkbox
            v-if="chooseType === 2"
            v-model="item.sel"
            @on-change="onSelChange"
          />
          <span
            v-else
            class="number-circle"
          >{{ i + 1 }}</span>
        </div>
        <div class="role-info">
          <div>{{ item.roleName }}</div>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import UserApiSet from '@/request/api/UserApiSet';
export default {
  components: {},
  props: {
    chooseType: {
      type: Number,
      // 1: 查看，2：编辑
      default: 1
    },
    // 已选中的角色
    chooseList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      sureButtonDisabled: true,
      // 全部角色
      roleDataList: []
    };
  },
  created () {
    if (this.chooseType === 2) {
      this.requestData();
    } else {
      this.roleDataList = this.chooseList || [];
    }
  },
  methods: {
    onClose () {
      this.$emit('on-close');
    },
    onSure () {
      this.$emit('on-sure', [...this.roleDataList.filter(v => v.sel)]);
    },
    onSelChange () {
      this.sureButtonDisabled = !this.roleDataList.find(v => v.sel);
    },
    requestData () {
      const api = UserApiSet.roleList;
      // 拉全量
      api.params = {
        pageSize: 9999,
        pageNo: 1
      };
      this.$http(api).then(res => {
        const resData = res.data;
        const roleDataList = [];
        resData.rows.forEach(v => {
          if (this.chooseList.length) {
            v.sel = !!this.chooseList.find(f => f.id === v.id);
          }
          roleDataList.push(v);
        });
        this.roleDataList = resData.rows;
      });
    }
  }
};
</script>
