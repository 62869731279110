import { EmployeeDataModel } from '../model/EmployeeDataModel';

const createRoleColumn = () => {
  const col = BaseTableColumn.initData('h5RoleStr', '角色名称');
  col.render = (h, { row, index }) => {
    let tooltipSlot = '';
    const tit = '<div class="display-flex; align-items: flex-end;" style="font-size: 16px;font-weight: 600;padding: 10px 0"><span>当前角色说明</span> <span style="font-size: 12px;"></span></div>';
    let content = '';
    const line1 = '<div style="border-bottom: 1px solid #ccc;"></div>';
    // const line2 = '<div style="background-color: #ebebeb; height: 4px; margin: 5px 0"></div>';
    if (row.roles.length && row.roles.length > 0) {
      content = '<p class="display-flex" style="padding: 5px;background:#ebebeb;"><span style="flex: 1;min-width:30%;max-width:30%;margin-right:10px;text-align: left">角色</span><span class="flex: 1; text-align: right;color:#000;">说明</span></p>';
      content += line1;
      row.roles.forEach((v, i) => {
        content += `<p class="display-flex" style="line-height: 14px;padding: 8px;"><span style="flex: 1; min-width:30%;max-width:30%;overflow:hidden;text-align: left;margin-right: 10px;white-space: nowrap">${v.roleName}</span><span class="flex: 1; text-align: right;color:#000;word-break: break-all;">${v.description}</span></p>`;
        if (i !== (row.roles.length - 1)) {
          content += line1;
        }
      });
    }
    tooltipSlot = '<div style="font-size: 12px; line-height: 22px;padding-bottom: 10px">' + tit + line1 + content + '</div>';
    return h('yg-attribute-span', {
      attrs: {
        text: row.h5RoleStr,
        canTooltip: !!row.h5RoleStr,
        tooltipWidth: 460,
        tooltipSlot: tooltipSlot,
        className: 'role-col-value'
      }
    }, row.h5RoleStr);
  };
  return col;
};

export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('index'),
        // BaseTableColumn.initData('userId', '账号编码').setSpan({ canCopy: true }),
        BaseTableColumn.initData('username', '登录账号').setSpan({ canCopy: true }),
        BaseTableColumn.initData('realName', '员工名称').setSpan({ canCopy: true }),
        BaseTableColumn.initData('mobile', '手机号').setSpan({ canHidePhone: true }),
        createRoleColumn(),
        // h5DataScopeStr
        BaseTableColumn.initData('h5DataScopeStr', '指定小区').setValueButton([], (row, index) => {
          this.requestDetail(row.userId, false);
        }),
        BaseTableColumn.initData('createTime', '创建时间').setSpan({}).setWidth(150),
        BaseTableColumn.initData('h5StatusSwitch', '状态').setSpan({}).setSwitch((e, row, index) => {
          row.h5StatusSwitch = e;
          this.$Modal.confirm({
            title: '操作提示',
            content: `是否确认${e ? '启用' : '停用'}`,
            onOk: () => {
              this.requestChangeStatus(row, e);
            },
            onCancel: () => {
              row.h5StatusSwitch = !e;
              this.$Message.info('操作已取消');
            }
          });
        }, 'system:user:status'),
        BaseTableColumn.initData('setting', '操作').setWidth(140).setAlign('center').setButtons([
          { title: '编辑账号', name: 'edit', permission: 'system:user:edit' },
          { title: '修改密码', name: 'password', permission: 'system:user:edit' }
        ], ({ name }, row, index) => {
          switch (name) {
          case 'edit':
            this.requestDetail(row.userId);
            break;
          case 'password':
            if (!row.mobile) return this.$Message.info('无手机号，无法修改密码');
            this.dialog.passwordModel = EmployeeDataModel.deepCopy(row);
            break;
          }
        })
      ]
    };
  }
};
