<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：员工管理 - 新增/编辑
    功能概述：新增、编辑、启用、禁用
-->
<style lang="less">
.employee-submit-content {
  .check-box-content {
    margin-top: 5px;
    .ivu-checkbox-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 15px;
      .ivu-checkbox {
        margin-top: 3px;
        margin-right: 6px;
      }
      .label-box {
        display: inline-block;
        line-height: initial;
        div:nth-child(2) {
          font-size: 12px;
          color: @buttonColorDisabled;
        }
      }
    }
  }
  .table-outer {
    .flex-grow(100%);
  }
  .emp-submit-table {
    border: 1px solid @lineColorNormal;
    border-bottom: 0;
    border-radius: @borderRadiusMin;
    .table-row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid @lineColorNormal;
      line-height: 24px;
      font-size: 12px;
      &.table-header {
        background-color: @backColorNormal;
        color: @textColorLight;
        font-weight: 600;
        font-size: 13px;
        .table-col {
          justify-content: center;
        }
      }
      .table-col {
        padding: 8px 5px;
        .display-flex();
        flex-wrap: wrap;
        &-1 {
          justify-content: flex-start;
          border-right: 1px solid @lineColorNormal;
          flex: 1;
        }
        &.no-data {
          justify-content:center;
          color: @textColorLight;
        }
        &-2 {
          .flex-grow(120px);
        }
      }
      .role-col-li {
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
        .table-col-li-inner {
          padding: 0 5px;
          .display-flex();
          height: 20px;
          line-height: 20px;
          background-color: rgba(84,110,253,0.2);
          cursor: default;
          color: @themeColor;
          border: 1px solid @themeColor;
          border-radius: 3px;
          white-space: nowrap;
          span {
            overflow: hidden;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:1;
          }
        }
      }
    }
  }
  .data-scope-tip {
    .flex-grow(100%);
    .display-flex();
    justify-content: flex-start;
    font-size: 12px;
    line-height: 12px;
    color: @textColorLight;
    span {
      color: @adornColor;
    }
  }
}
</style>

<template>
  <yg-modal
    width="600"
    :title="(model.id ? '编辑' : '新增') + '员工'"
    :back-type="2"
    sure-text="保存"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 200px"
    >
      <div class="employee-submit-content">
        <yg-card
          v-for="(item, index) in formItems"
          :key="index"
          style="margin-bottom: 15px"
          :title="item.title"
        >
          <div
            slot="content"
            class="padding-primary-top"
          >
            <Form>
              <FormItem
                v-for="(sub, ind) in item.items"
                :key="ind"
                :label="sub.label"
                :label-width="sub.labelWidth"
                :required="sub.isRequired"
              >
                <Input
                  v-if="sub.tagName === 'Input'"
                  v-model="model[sub.key]"
                  :type="sub.type"
                  :minlength="sub.minLength"
                  :maxlength="sub.maxLength"
                  :placeholder="'请输入' + sub.label"
                  :disabled="sub.disabled"
                  @input="checkSubmit"
                />
                <!--展示角色-->
                <div
                  v-if="sub.key === 'role'"
                  class="table-outer"
                >
                  <div
                    class="emp-submit-table"
                  >
                    <div class="table-row table-header">
                      <div class="table-col table-col-1">
                        已选角色
                      </div>
                      <div class="table-col table-col-2">
                        操作
                      </div>
                    </div>
                    <div class="table-row">
                      <!--有选择的角色-->
                      <div
                        v-if="model.roles.length"
                        class="table-col table-col-1"
                      >
                        <div
                          v-for="(role, r) in model.roles"
                          :key="'role-' + r"
                          class="role-col-li"
                        >
                          <div class="table-col-li-inner">
                            {{ role.roleName }}
                          </div>
                        </div>
                      </div>
                      <!--无选择的角色-->
                      <div
                        v-else
                        class="table-col table-col-1 no-data"
                      >
                        暂未选择
                      </div>
                      <div class="table-col table-col-2">
                        <yg-table-buttons
                          :items="roleTableItems"
                          @on-click="_clickChooseRole"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--可见小区-->
                <RadioGroup
                  v-if="sub.key === 'dataScope'"
                  v-model="model[sub.key]"
                  :vertical="false"
                  @on-change="checkSubmit"
                >
                  <Radio
                    v-for="(data, r) in sub.data"
                    :key="'r-' + r"
                    :label="data.value"
                    :disabled="!canChangeArea"
                  >
                    <span>{{ data.label }}</span>
                  </Radio>
                </RadioGroup>
                <!--可见小区扩展: 全部小区-->
                <div
                  v-if="sub.key === 'dataScope' && model.dataScope === 1"
                  class="data-scope-tip"
                >
                  <span>*</span>
                  注：即当前组织下的所有小区范围
                </div>
                <!--可见小区扩展：指定小区-->
                <div
                  v-else-if="sub.key === 'dataScope' && model.dataScope === 2"
                  class="table-outer"
                  style="margin-top: 10px"
                >
                  <div
                    class="emp-submit-table"
                  >
                    <div class="table-row table-header">
                      <div class="table-col table-col-1">
                        已选小区
                      </div>
                      <div class="table-col table-col-2">
                        操作
                      </div>
                    </div>
                    <div class="table-row">
                      <!--有选择的角色-->
                      <div
                        v-if="model.communities.length"
                        class="table-col table-col-1"
                      >
                        <div
                          class="role-col-li"
                        >
                          {{ model.communities[0].name }}
                          <span v-if="model.communities.length > 1">等{{ model.communities.length }}个小区</span>
                        </div>
                      </div>
                      <!--无选择的角色-->
                      <div
                        v-else
                        class="table-col table-col-1 no-data"
                      >
                        暂未选择
                      </div>
                      <div class="table-col table-col-2">
                        <yg-table-buttons
                          :items="communityTableItems"
                          @on-click="_clickChooseCommunity"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FormItem>
            </Form>
          </div>
        </yg-card>
        <!--弹窗：选择角色-->
        <v-choose-role
          v-if="roleDialog.show"
          :choose-type="roleDialog.chooseType"
          :choose-list="model.roles"
          @on-close="roleDialog.show = false"
          @on-sure="onSureRole"
        />
        <!--弹窗：选择小区-->
        <yg-community-selector
          v-if="communityDialog.selectorShow"
          :data="model.communities"
          @on-close="communityDialog.selectorShow = false"
          @on-sure="onSureCommunity"
        />
        <!--弹窗：小区详情-->
        <yg-community-detail
          v-if="communityDialog.detailShow"
          :data="model.communities"
          @on-close="communityDialog.detailShow = false;"
        />
      </div>
    </div>
  </yg-modal>
</template>

<script>
import vChooseRole from './chooseRole.vue';
export default {
  components: { vChooseRole },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: true,
      buttonDisabledMsg: '',
      // 表单数据
      formItems: [
        {
          title: '员工基础信息',
          items: [
            {
              label: '姓名',
              labelWidth: 100,
              key: 'realName',
              tagName: 'Input',
              disabled: !!this.model.id,
              isRequired: true,
              type: 'text',
              minLength: '4',
              maxLength: '40'
            },
            {
              label: '手机号',
              labelWidth: 100,
              key: 'mobile',
              tagName: 'Input',
              disabled: !!this.model.id,
              isRequired: true,
              type: 'text',
              minLength: '4',
              maxLength: '20'
            },
            {
              label: '角色',
              labelWidth: 100,
              key: 'role',
              tagName: 'Div',
              isRequired: true,
              data: [
                {
                  label: '运营人员',
                  value: '1',
                  warn: '运营人员专用'
                },
                {
                  label: '设备安装人员专用',
                  value: '2',
                  warn: '只有激活设备功能，其他都没有'
                },
                {
                  label: '总部财务专用',
                  value: '3',
                  warn: '总部财务专用'
                }
              ]
            },
            {
              label: '可见小区',
              labelWidth: 100,
              key: 'dataScope',
              disabled: false,
              isRequired: true,
              data: [
                {
                  label: '全部小区',
                  value: 1
                },
                {
                  label: '指定小区',
                  value: 2
                }
              ]
            }
          ]
        },
        {
          title: '登录信息设置',
          items: [
            {
              label: '登录账号',
              labelWidth: 100,
              key: 'username',
              tagName: 'Input',
              disabled: !!this.model.id,
              isRequired: true,
              type: 'text',
              minLength: '4',
              maxLength: '40'
            },
            {
              label: '登录密码',
              labelWidth: 100,
              key: 'password',
              tagName: 'Input',
              disabled: !!this.model.id,
              isRequired: true,
              type: 'password',
              minLength: '6',
              maxLength: '8'
            },
            {
              label: '确认密码',
              labelWidth: 100,
              key: 'h5PasswordAgain',
              tagName: 'Input',
              disabled: !!this.model.id,
              isRequired: true,
              type: 'password',
              minLength: '6',
              maxLength: '8'
            }
          ]
        }
      ],
      roleDialog: {
        show: false,
        chooseType: 1
      },
      // 刚开始时候的角色
      orgRoleIdStr: '',
      communityDialog: {
        selectorShow: false,
        detailShow: false
      },
      communityDisplayName: '',
      // 刚开始时候的小区
      orgCommunityIdStr: '',
      // 只有userType为非20的账号才可以指定下面人的小区
      canChangeArea: true
    };
  },
  computed: {
    roleTableItems () {
      return !this.model.roles.length ? [
        { title: '请选择', name: 2 }
      ] : [
        { title: '编辑', name: 2 },
        { title: '详情', name: 1 }
      ];
    },
    communityTableItems () {
      return !this.model.communities.length ? [
        { title: '请选择', name: 2 }
      ] : [
        { title: '编辑', name: 2 },
        { title: '详情', name: 1 }
      ];
    }
  },
  created () {
    // this.canChangeArea = this.$storage.user.userType !== this.$config.systemUserType.service;
  },
  methods: {
    // 工具：获取Id集合字符串
    getIdsString (list) {
      const arr = [];
      if (Array.isArray(list)) {
        list.forEach(v => {
          arr.push(v.id);
        });
      }
      return arr.join(',');
    },
    onSure () {
      this.$emit('on-sure');
    },
    onClose () {
      this.$emit('on-close');
    },
    // 确定角色
    onSureRole (e) {
      this.model.roles = e;
      this.model.h5RoleListStr = this.getIdsString(this.model.roles);
      this.roleDialog.show = false;
      this.checkSubmit();
    },
    // 确定小区
    onSureCommunity ({ list }) {
      const newList = list.filter(v => v.cate === 4);
      this.model.communities = newList;
      this.model.h5CommunityListStr = this.getIdsString(newList);
      this.communityDialog.selectorShow = false;
      this.checkSubmit();
    },
    _clickChooseRole ({ name }) {
      this.roleDialog.chooseType = name;
      this.roleDialog.show = true;
    },
    _clickChooseCommunity ({ name }) {
      if (name === 1) {
        this.communityDialog.detailShow = true;
      } else {
        this.communityDialog.selectorShow = true;
      }
    },
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
      if (!this.buttonDisabled) {
        // 校验角色
        if (!this.model.roles.length) {
          this.buttonDisabled = true;
          this.buttonDisabledMsg = '角色为空';
          return;
        }
        // 指定小区
        if (this.model.dataScope === 2 && !this.model.communities.length) {
          this.buttonDisabled = true;
          this.buttonDisabledMsg = '指定小区为空';
        }
      }
    }
  }
};
</script>
