import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isAlphanumeric, isNumber, isPhone, isValidString1 } from '@/libs/regularUtils';
import md5 from 'md5';

export class EmployeeDataModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    this.userId = '';
    this.mobile = ''; // 手机号
    this.username = ''; // 登录账号
    this.realName = ''; // 名称
    this.status = '';
    this.createTime = '';
    this.password = '';
    this.roles = [];
    this.dataScope = 1;
    // 前端自有
    this.h5StatusSwitch = false;
    this.h5DataScopeStr = '';
    this.h5RoleStr = '';
    this.h5Code = '';
    this.h5CodeUUid = '';
  }

  initData (resData) {
    super.initData(resData);
    this.h5StatusSwitch = !!this.status;
    this.id = this.userId;
    if (Array.isArray(resData.roles) && resData.roles.length > 0) {
      this.h5RoleStr = resData.roles[0].roleName;
      this.h5RoleStr += resData.roles.length > 1 ? '等' : '';
    }
    this.h5DataScopeStr = this.dataScope === 1 ? '' : '已指定';
    return this;
  }

  /**
   * 数据具体校验-修改密码
   * @returns {*}
   */
  checkPasswordValid () {
    if (!isAlphanumeric(this.password)) {
      return BaseModel.getValidRes(false, '6~8位密码，必须为数字字母组合');
    } else if (!this.h5Code) {
      return BaseModel.getValidRes(false, '请输入验证码');
    }
    return BaseModel.getValidRes(true, '', {
      username: this.username,
      password: md5(this.password),
      code: this.h5Code,
      uuid: this.h5CodeUUid
    });
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        id: '000101010' + i,
        tel: '13904288000',
        province: BaseModel.getFakeTitle(4) + '省',
        city: BaseModel.getFakeTitle(6) + '市',
        area: BaseModel.getFakeTitle(6) + '区',
        mainPrice: parseFloat((Math.random() * 99999).toFixed(2)),
        lightPrice: parseFloat((Math.random() * 30000).toFixed(2)),
        status: i % 2
      };
      list.push(dic);
    }
    return list;
  }
}

export class EmployeeSubmitDataModel extends EmployeeDataModel {
  constructor () {
    super();
    this.roles = [];
    // 数据范围（1：全部小区 2：关联小区数据）
    this.dataScope = 1;
    // 绑定小区数据（）
    this.communities = [];
    this.h5PasswordAgain = ''; // 确认密码时使用
    // 前端独有
    this.h5CommunityListStr = ''; // 小区id字符串拼接
    this.h5RoleListStr = ''; // 角色id字符串拼接
  }

  initData (resData) {
    super.initData(resData);
    const communities = [];
    if (Array.isArray(resData.userCommunityList)) {
      resData.userCommunityList.forEach(v => {
        communities.push({
          id: v.communityId,
          name: v.communityName
        });
      });
    }
    this.communities = communities;
    if (Array.isArray(this.communities)) {
      const arr = [];
      this.communities.forEach(v => {
        arr.push(v.id);
      });
      this.h5CommunityListStr = arr.join(',');
    }
    if (Array.isArray(this.roles)) {
      const arr = [];
      this.roles.forEach(v => {
        arr.push(v.id);
      });
      this.h5RoleListStr = arr.join(',');
    }
    // this.h5StatusSwitch = !!this.status;
    return this;
  }

  /**
   * 数据具体校验-新增/编辑
   * @returns {*}
   */
  checkSubmitValid () {
    // 专项校验
    if (!this.h5RoleListStr) {
      return BaseModel.getValidRes(false, '请选择角色');
    } else if (this.dataScope === 2 && !this.h5CommunityListStr) {
      return BaseModel.getValidRes(false, '请指定小区');
    }
    console.log(this);
    if (!this.id) {
      // 新增数据时-校验
      if (!isPhone(this.mobile)) {
        return BaseModel.getValidRes(false, '手机号码不合规，请重新输入');
      } else if (!isValidString1(this.realName) || this.realName.length > 40) {
        return BaseModel.getValidRes(false, '员工的名称最长可输入40个字，可汉字、数字、字母或符号组合');
      } else if (this.username.length < 6 || this.username.length > 40) {
        return BaseModel.getValidRes(false, '请输入6-40位登录账号');
      } else if (isNumber(this.username)) {
        return BaseModel.getValidRes(false, '账号不可纯数字');
      } else if (!isAlphanumeric(this.password)) {
        return BaseModel.getValidRes(false, '6~8位密码，必须为数字字母组合');
      } else if (this.password !== this.h5PasswordAgain) {
        return BaseModel.getValidRes(false, '两次输入的密码不同，请重新输入');
      }
      // return {};
    }
    const res = BaseModel.getValidRes(true, '', {
      userId: this.id,
      dataScope: this.dataScope,
      roles: this.h5RoleListStr.split(','),
      communities: this.h5CommunityListStr.split(','),
      password: 'xxxx',
      realName: this.realName,
      mobile: this.mobile,
      username: this.username
    });
    if (!this.id) {
      res.params.username = this.username;
      res.params.password = md5(this.password);
      res.params.realName = this.realName;
      res.params.mobile = this.mobile;
    }
    return res;
  }

  static createAdditionModel () {
    return new this();
  }
}
