<!--
    Created by 程雨喵'mac on 2024/2/26.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：修改密码
-->
<style lang="less">
.employee-password {
  .code-img-outer {
    margin-left: 10px;
    .display-flex();
    .flex-grow(90px);
    border-radius: @borderRadiusMin;
    overflow: hidden;
    background-color: @backColorNormal;
    &.error-img {
      .flex-grow(80px);
      height: auto;
    }
    img {
      //.flex-grow(78px);
      height: 26px;
    }
  }
  .refresh-icon {
    .flex-grow(20px);
    .display-flex();
    color: @textColorLight;
    .ivu-icon {
      font-size: 16px;
    }
  }
}
</style>

<template>
  <yg-modal
    title="修改密码"
    width="420"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledText"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="employee-password"
    >
      <Form>
        <FormItem
          v-for="(item, i) in inputItems"
          :key="i"
          :label="item.label + ':'"
          :label-width="item.labelWidth"
          :required="item.isRequired"
        >
          <Input
            v-model.trim="model[item.key]"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
            class="display-flex-1"
            @input="checkSubmit"
          />
          <div
            v-if="item.key === 'h5Code'"
            :class="form.verifyUrl ? '' : 'error-img'"
            class="code-img-outer"
            @click.stop="_clickCode"
          >
            <img
              :src="form.verifyUrl || '/icon/img_error.png'"
            >
            <div class="refresh-icon">
              <Icon type="md-refresh" />
            </div>
          </div>
        </FormItem>
      </Form>
    </div>
  </yg-modal>
</template>

<script>
import UserApiSet from '@/request/api/UserApiSet';
import { isPhone } from '@/libs/regularUtils';

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      inputItems: [
        { label: '登录账号', labelWidth: 100, key: 'username', isRequired: true, disabled: true },
        { label: '登录密码', labelWidth: 100, placeholder: '请输入6~8位密码，必须为数字字母组合', key: 'password', isRequired: true },
        { label: '验证码', labelWidth: 100, placeholder: '请输入图形验证码', key: 'h5Code', isRequired: true }
      ],
      buttonDisabled: true,
      buttonDisabledText: '',
      form: {
        verifyUrl: ''
      }
    };
  },
  computed: {
    optDisabled () {
      return !isPhone(this.model.mobile);
    }
  },
  mounted () {
    this._clickCode();
  },
  methods: {
    onSure () {
      this.$emit('on-sure');
    },
    onClose () {
      this.$emit('on-close');
    },
    _clickCode () {
      const api = UserApiSet.captchaApi;
      this.$http(api).then(res => {
        const resData = res.data;
        this.form.verifyUrl = 'data:image/png;base64,' + resData.image;
        this.model.h5CodeUUid = resData.uuid;
        this.model.h5Code = '';
      });
    },
    checkSubmit () {
      const item = this.inputItems.find(v => !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledText = item ? (item.label + '不可为空') : '';
    }
  }
};
</script>
